import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toggleable"]

  connect() {
    this.toggleClass = this.data.get("class") || "hidden"
  }

  toggle(event) {
    event.preventDefault()

    this.toggleableTargets.forEach(target => {
      target.classList.toggle(this.toggleClass)

      if (target.classList.contains('hidden')) {
        event.target.textContent = this.baseText
      } else {
        event.target.textContent = this.activeText
      }
    })
  }

  get activeText() {
    return this.data.get('active-text')
  }

  get baseText() {
    return this.data.get('base-text')
  }
}
