import { Controller } from "stimulus"

export default class extends Controller {
  show() {
    document.getElementById("property-search--popup").style.display = "block"
  }
  
  hide() {
    document.getElementById("property-search--popup").style.display = "none"
  }
}