import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    if (this.data.has('name') && this.params.has(this.data.get('name'))) {
      this.element.focus()
    }
  }

  get params() {
    return new URLSearchParams(window.location.search.substring(1))
  }
}
