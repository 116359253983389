import { Controller } from 'stimulus'
import { uniqBy, find, filter } from 'lodash'
import { get } from 'axios'

export default class extends Controller {
  static targets = [ 'container', 'tag' ]

  filter(e) {
    filter(this.tagTargets, (el) => {
      return el.dataset.id == e.target.dataset.id && el !== el.target
    }).forEach((el) => {
      return el.classList.toggle('checked')
    })

    setTimeout(() => {
      get(this.data.get('path'), {
        params: {
          tag_ids: this.checkedTagIds
        },
        data: {},
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      }).then((response) => {
        this.container.innerHTML = response.data
      })
    }, 100)
  }

  get checkedTagIds() {
    return filter(this.tags, (el) => el.classList.contains('checked')).map((el) => el.dataset.id)
  }

  get tags() {
    return uniqBy(this.tagTargets, (el) => el.dataset.id)
  }

  get container() {
    return this.containerTarget
  }
}
