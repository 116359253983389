import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ 'section' ]

  connect() {
    this.sectionTargets.map((target) => {
      $(target.querySelector('.collapse')).on('show.bs.collapse', () => { target.classList.add('active') })
      $(target.querySelector('.collapse')).on('hide.bs.collapse', () => { target.classList.remove('active') })
    })
  }
}
