import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.showSignupForm()
  }
  
  showResetForm() {
    document.getElementById("property-search--sign-in--form__sign-in").style.display = "none"
    document.getElementById("property-search--sign-in--form__sign-up").style.display = "none"
    document.getElementById("property-search--sign-in--form__reset").style.display = "block"

    document.getElementById("property-search--sign-in--controls--reset").style.display = "none"
    document.getElementById("property-search--sign-in--controls--sign-in").style.display = "none"
    document.getElementById("property-search--sign-in--controls--sign-up").style.display = "block"
  }

  showSignupForm() {
    document.getElementById("property-search--sign-in--form__sign-in").style.display = "none"
    document.getElementById("property-search--sign-in--form__reset").style.display = "none"
    document.getElementById("property-search--sign-in--form__sign-up").style.display = "block"

    document.getElementById("property-search--sign-in--controls--sign-up").style.display = "none"
    document.getElementById("property-search--sign-in--controls--reset").style.display = "none"
    document.getElementById("property-search--sign-in--controls--sign-in").style.display = "block"
  }

  showSigninForm() {
    document.getElementById("property-search--sign-in--form__reset").style.display = "none"
    document.getElementById("property-search--sign-in--form__sign-up").style.display = "none"
    document.getElementById("property-search--sign-in--form__sign-in").style.display = "block"

    document.getElementById("property-search--sign-in--controls--sign-in").style.display = "none"
    document.getElementById("property-search--sign-in--controls--reset").style.display = "block"
    document.getElementById("property-search--sign-in--controls--sign-up").style.display = "block"

  }
}
