import { Controller } from "stimulus"
import { fetch as fetchPolyfill } from 'whatwg-fetch'

export default class extends Controller {
  connect() {
    this.load()
  }

  disconnect() {
    this.stopRefreshing()
  }

  load() {
    fetchPolyfill(this.data.get("url"), { credentials: 'same-origin' })
      .then(response => {
        if (response.status == 200) {
          this.stopRefreshing()
          document.getElementById("property-search--report--header--download-pdf").style.display = "block"
          return response.text()

        } else if (response.status == 400) {
          document.getElementById("property-search--report--owner-info--placeholder").style.display = "none"
          document.getElementById("property-search--report--owner-info--error").style.display = "block"
          this.stopRefreshing()
          throw Error(response.text())

        } else {
          this.startRefreshing()
          throw Error(response.text())

        }
      })
      .then(html => {
        this.element.innerHTML = html
      })
  }

  startRefreshing() {
    if (!this.refreshTimer) {
      this.refreshTimer = setInterval(() => { this.load() }, "5000")
    }
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
}
