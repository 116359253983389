import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    // this.showPopup()
  }

  disconnect() {
    if (window.timer) {
      clearTimeout(window.timer)
    }
  }

  showPopup() {
    const sleep = (milliseconds) => {
      return new Promise(resolve => window.timer = setTimeout(resolve, milliseconds))
    }

    sleep(10000).then(() => {
      document.getElementById("content").classList.add("blurred")
      document.getElementById("signup-popup").style.display = "block"
    })
  }
}
