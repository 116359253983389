import { Controller } from "stimulus"

import * as jsPDF from "jspdf"

export default class extends Controller {
  download() {
    var pdf = new jsPDF()
    pdf.fromHTML(document.getElementById(this.data.get("id")), 15, 15, {'width': 180})
    pdf.save(this.data.get("name"))
  }
}